import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import { Container, H1 } from "@util/standard";
import { BlocksContent } from "@components/global";
import { Query } from "@graphql-types";
import Cta from "@components/global/cta";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import Layout from "@shared/layout";

const TextContainer = styled(Container)`
  flex-direction: column;
  margin: 300px auto 60px auto;
  width: 65%;
`;

const NotFoundPage = () => {
  const { sanityPageNotFound }: Query = useStaticQuery(graphql`
    {
      sanityPageNotFound {
        pageNotFoundText {
          ...sanityColumn
        }
        pageNotFoundCtas {
          ...sanityCta
        }
        categoryPreview {
          ...sanityLearnCategoryPreview
        }
      }
    }
  `);

  if (sanityPageNotFound == null) {
    return (
      <Container>
        <H1>404 Page Not Found</H1>
      </Container>
    );
  }

  const { pageNotFoundText, pageNotFoundCtas, categoryPreview } = sanityPageNotFound;

  return (
    <>
      <Layout>
        <Container flexDirection="column">
          {/* <Container width="100%" height="106px" tabletHeight="106px" backgroundColor="darkerGreen" /> */}
          <TextContainer>
            {pageNotFoundText && <BlocksContent blocks={pageNotFoundText._rawColumnContent} />}
          </TextContainer>
          {pageNotFoundCtas && (
            <Container width="65%" margin="0 auto 325px auto">
              {pageNotFoundCtas.map(cta => {
                if (cta == null) {
                  return null;
                }
                return <Cta whiteBackground data={cta} key={cta._key} />;
              })}
            </Container>
          )}
          {categoryPreview && <LearnCategoryPreview data={categoryPreview} />}
        </Container>
      </Layout>
    </>
  );
};

export default NotFoundPage;
